import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { ContactForm } from "@/components/contact-form";
import { Timeline } from "@/components/timeline";
import { Arrow } from "@/components/arrow";
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo";
import { Layout } from "@/components/layout";

const CloudComputing = () => {
  return (
    <>
      <GatsbySeo
        title="Cloud Computing Services in Malaysia | Tectra Technologies"
        description="Cloud Computing - Tectra technology offers a vast array of cloud services, that gives our clients the needed flexibility"
        canonical="https://www.tectratechnologies.com/cloud-computing/"
        openGraph={{
          locale: "en_US",
          type: "article",
          url: "https://www.tectratechnologies.com/cloud-computing/",
          title: "Cloud Computing Services in Malaysia | Tectra Technologies",
          description:
            "Cloud Computing - Tectra technology offers a vast array of cloud services, that gives our clients the needed flexibility",
          site_name: "Tectra Technologies",
        }}
        twitter={{
          handle: "@handle",
          site: "@TectraL",
          cardType: "summary_large_image",
        }}
      />
      <JsonLd
        json={{
          "@context": "https://schema.org",
          "@type": "WebSite",
          "@graph": [
            {
              "@type": "WebSite",
              "@id": "https://www.tectratechnologies.com/#website",
              url: "https://www.tectratechnologies.com/",
              name: "Tectra Technologies",
              description:
                "Web &amp; Mobile App, Cloud, UI/UX, Devops, Tax Services, Digital Marketing",
              potentialAction: [
                {
                  "@type": "SearchAction",
                  target: {
                    "@type": "EntryPoint",
                    urlTemplate:
                      "https://www.tectratechnologies.com/?s={search_term_string}",
                  },
                  "query-input": "required name=search_term_string",
                },
              ],
              inLanguage: "en-US",
            },
            {
              "@type": "WebPage",
              "@id": "https://www.tectratechnologies.com/cloud-computing/",
              url: "https://www.tectratechnologies.com/cloud-computing/",
              name: "Cloud Computing Services in Malaysia | Tectra Technologies",
              isPartOf: {
                "@id": "https://www.tectratechnologies.com/#website",
              },
              datePublished: "2019-08-21T06:12:48+00:00",
              dateModified: "2022-01-31T09:55:36+00:00",
              description:
                "Cloud Computing - Tectra technology offers a vast array of cloud services, that gives our clients the needed flexibility",
              breadcrumb: {
                "@id":
                  "https://www.tectratechnologies.com/cloud-computing/#breadcrumb",
              },
              inLanguage: "en-US",
              potentialAction: [
                {
                  "@type": "ReadAction",
                  target: [
                    "https://www.tectratechnologies.com/cloud-computing/",
                  ],
                },
              ],
            },
            {
              "@type": "BreadcrumbList",
              "@id":
                "https://www.tectratechnologies.com/cloud-computing/#breadcrumb",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Home",
                  item: "https://www.tectratechnologies.com/",
                },
                {
                  "@type": "ListItem",
                  position: 2,
                  name: "Cloud Computing | Cloud Consulting | Cloud Migration",
                },
              ],
            },
          ],
        }}
      />
      <Layout>
        <div className="bg-white h-screen flex items-center justify-center">
          <div className="w-full max-w-7xl mx-auto px-5 lg:px-8">
            <div className="text-left xl:w-3/5">
              <div className="">
                <h1 className="text-black text-4xl font-poppins font-semibold mb-4 normal-case leading-relaxed">
                  Embark on your cloud transformation journey with tectra.
                </h1>
                <div className="relative flex overflow-hidden justify-start items-center text-center">
                  <Link
                    to="/contact"
                    className="flex items-center text-xl normal-case hover:text-black"
                    activeClassName="border-b-2 border-black"
                  >
                    Talk to us
                    <Arrow color="black" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white mt-10 lg:mt-12">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-center text-2xl font-poppins font-semibold captalize">
              What makes our cloud computing services the best?
            </h2>
            <p className="max-w-5xl mx-auto text-center text-base text-black font-poppins font-thin leading-8 mt-4">
              We help you build a strong business foundation with our full stack
              software development and cloud computing services. Our experts
              create roadmaps to reach your desired goals and which is why we
              stand out among others. Here are the reasons to choose Tectra
              Technologies for cloud computing services:
            </p>
            <div className="mt-8">
              <div className="w-full h-full">
                <StaticImage
                  src="../../images/ojpoelknatf7887rw.png"
                  alt=""
                  layout="constrained"
                  placeholder="blurred"
                  quality={95}
                  height={500}
                  className="w-full h-60 md:h-92 lg:h-auto"
                  formats={["auto", "png"]}
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 lg:gap-8 mt-8 lg:mt-12">
                <div className="text-black text-left">
                  <h3 className="text-xl font-poppins font-semibold captalize pl-2 border-l-4 border-[#9A9A9A] leading-none">
                    Strategy Building
                  </h3>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4">
                    Our experts have in-depth knowledge about software products
                    and they have worked on real-time challenges while working
                    in different organizations with a wide range of products.
                    Thus, we build cloud-native transformation strategies that
                    actually work for your business.
                  </p>
                </div>
                <div className="text-black text-left">
                  <h3 className="text-xl font-poppins font-semibold captalize pl-2 border-l-4 border-[#9A9A9A] leading-none">
                    Multi-Cloud Environment
                  </h3>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4">
                    With experience working across multi-cloud products, our
                    experts have developed great knowledge. Our high-end
                    professionals deliver you the best end product with their
                    knowledge and skills.
                  </p>
                </div>
                <div className="text-black text-left">
                  <h3 className="text-xl font-poppins font-semibold captalize pl-2 border-l-4 border-[#9A9A9A] leading-none">
                    Cost Saving
                  </h3>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4">
                    Our cloud computing services are highly cost-saving. We
                    focus on improving our performance by providing you with
                    cost-effective measures. Thus, our services are cost-saving.
                  </p>
                </div>
                <div className="text-black text-left">
                  <h3 className="text-xl font-poppins font-semibold captalize pl-2 border-l-4 border-[#9A9A9A] leading-none">
                    Technical Support
                  </h3>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4">
                    Our development teams provide you with complete technical
                    support from beginning to end. We investigate on root causes
                    of issues in order to fix the core problem. Our technical
                    team also maintains hi confidentiality of your data.
                  </p>
                </div>
              </div>
              <div className="mt-8 lg:mt-12">
                <h2 className="text-black text-center text-2xl font-poppins font-semibold captalize">
                  What makes our cloud computing services the best?
                </h2>
                <p className="max-w-5xl mx-auto text-center text-base text-black font-poppins font-thin leading-8 mt-4">
                  From the commencement of our journey, we have helped many
                  businesses to start from scratch and build core competencies.
                  Our team catered to the needs of every business from a startup
                  to an enterprise with multiple operations. Our successful
                  track records are proof of us being ultimately professional at
                  our work. We leverage the best cloud-based practice to help
                  businesses grow more. We work as your development partner to
                  build your brand.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="relative overflow-hidden bg-white mt-10 lg:mt-12">
          <div className="mx-auto max-w-7xl px-0 lg:px-8 relative">
            <div className="grid grid-cols-1 items-center justify-center lg:gap-y-2 lg:grid-cols-12">
              <div className="flex flex-wrap items-center justify-center pb-4 lg:pr-12 sm:mx-0 sm:pb-0 lg:col-span-6 order-2 lg:order-1">
                <div className="relative z-10 gap-x-4 px-5 lg:block lg:gap-x-0 lg:gap-y-1">
                  <p className="text-base text-black font-poppins font-thin leading-8 mb-0">
                    At Tectra Technologies, we apprise our clients about the
                    competencies of the cloud infrastructure and solutions, and
                    how they can impact their businesses unconventionally. From
                    cloud migration to cloud management to cloud-integrated
                    storage, we do it all for our clients while ensuring smooth
                    progression at each stage.
                  </p>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4 mb-0">
                    To handle the extreme growth induced by increased adoption
                    of advanced technologies like Artificial Intelligence,
                    Machine Learning, Data, and the Internet of Things in all
                    industries, resilient infrastructures capable of handling
                    extreme growth are required. With Google, Azure, and AWS
                    cloud expertise, our cloud architects develop highly
                    reliable and secure cloud solutions. We offer different
                    cloud services and solutions according to the varying needs
                    and specifications of all businesses.
                  </p>
                </div>
              </div>
              <div className="lg:col-span-6 order-1 lg:order-2 mb-2 lg:mb-2">
                <div className="relative w-auto overflow-hidden lg:mt-0 lg:w-[67.8125rem]">
                  <div className="w-full h-full">
                    <StaticImage
                      src="../../images/cloud-computing-01.png"
                      alt=""
                      layout="constrained"
                      placeholder="blurred"
                      quality={95}
                      height={350}
                      className="w-full"
                      formats={["auto", "png"]}
                    />
                  </div>
                  <div className="w-full h-full mt-4">
                    <StaticImage
                      src="../../images/cloud-computing-02.png"
                      alt=""
                      layout="constrained"
                      placeholder="blurred"
                      quality={95}
                      height={350}
                      className="w-full"
                      formats={["auto", "png"]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="bg-white mt-10 lg:my-12">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-left text-2xl font-poppins font-semibold captalize">
              Valued-Added Cloud Services
            </h2>
            <div className="mt-6 ml-2 lg:ml-0">
              <Timeline
                items={[
                  "Multi-cloud",
                  "Openstack Services",
                  "Data Protection & Backup, Recovery",
                  "Cloud Migration",
                  "Private Cloud Enablement Services",
                ]}
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CloudComputing;
